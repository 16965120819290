import React from "react";
import { Chip } from "@mui/material";

const UserConnected = ({ status }) => {
  // Determine the color based on the status value
  const getColor = () => {
    if (status) {
      return { color: "#1b5e20", text: "ON" };
    } else {
      return { color: "#d50000", text: "OFF" };
    }
    // if (status >= 0 && status <= 20) {
    //   return {color:"#d50000",text:"Low Battery"};
    // } else if (status >= 21 && status <= 50) {

    //   return {color:"#ffe57f",text:"Medium Battery"};
    // } else if (status >= 51) {
    //   return {color:"#1b5e20",text:"High Battery"};
    // }
    // return "grey"; // Default color for invalid statuses
  };

  const color = getColor();

  return (
    <Chip
      label={
        <div style={{ textAlign: "center" }}>
          <span>{color.text}</span>
        </div>
      }
      style={{
        backgroundColor: color.color,
        color: "white",
        borderRadius: "5px",
        fontWeight: "bold",
        height: "auto",
        padding: "5px",
      }}
    />
  );
};

export default React.memo(UserConnected);
