import React, { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import socket from '../socket/SocketService';
import ArrivalDialogBox from "../DialogBox/Arrival";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const options = [
  'Check Arriaval',
  'send push notification',
];

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(props)
    console.log(event.currentTarget)
  };

  const handleClose = (option) => {
   var socketId=props?.props?.socketId || props?.props?.userData?.socketId
    console.log(option,props.props)
    console.log({socketId:props?.props?.userData?.socketId})
    console.log("final id",socketId)
    if(option==0){
      socket.emit("emitarrivalpopup", {socketId:socketId});
      toast.info(`Requested for feedback to the user`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if(option==1){
        setOpenModal(true);
    }
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    console.log("handleCloseModal")
    setOpenModal(false);
  };
  return (
    <div>
      {openModal && <ArrivalDialogBox userInfo={props?.props} 
          open={openModal}
          onClose={handleCloseModal} />}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '25ch',
            },
          },
        }}
      >
        {options.map((option,index) => (
          <MenuItem key={option}  onClick={()=>handleClose(index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}