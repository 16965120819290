import React, { useEffect, useState } from 'react';

const Timer = ({ firebaseTime }) => {
  const [timer, setTimer] = useState('');
  const [lastFirebaseTime, setLastFirebaseTime] = useState(null);

  useEffect(() => {
    // Only update lastFirebaseTime and reset timer if firebaseTime changes
    if (firebaseTime && firebaseTime !== lastFirebaseTime) {
      setLastFirebaseTime(firebaseTime);
    }
  }, [firebaseTime, lastFirebaseTime]);

  useEffect(() => {
    if (!lastFirebaseTime) return;

    // Function to calculate time difference
    const calculateTimeDifference = () => {
      const firebaseDate = new Date(lastFirebaseTime); // Convert Firebase time to Date
      const now = new Date(); // Current time
      const differenceInSeconds = Math.floor((now - firebaseDate) / 1000); // Difference in seconds
      const minutes = Math.floor(differenceInSeconds / 60);
      const seconds = differenceInSeconds % 60;
      setTimer(`${minutes} minutes and ${seconds} seconds`);
    };

    // Update timer every second
    const intervalId = setInterval(() => {
      calculateTimeDifference();
    }, 1000);

    // Cleanup interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [lastFirebaseTime]); // This ensures the timer is updated every second based on the latest lastFirebaseTime

  return (
    <div>
      {timer}
    </div>
  );
};
export default React.memo(Timer);
