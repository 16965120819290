import * as React  from 'react';
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { getDatabase, ref, onValue, set, get, remove } from "firebase/database";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import { styled } from '@mui/system';
import { CssTransition } from '@mui/base/Transitions';
import { PopupContext } from '@mui/base/Unstable_Popup';
import { green, red } from '@mui/material/colors';
import { useSelector } from "react-redux";
import EditUserModel from 'components/EditModelOpen';
import AddUserModel from 'components/AddUser';

export  function MenuIntroduction(user) {
 
   
const [isModalOpen,setIsModalOpen] = useState(false);
      const [rows, setRows] = useState([]);
  const allData = useSelector((state) => state.data.allData);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [vehicleModal, setVehicleModal] = useState(false);

  const [editedVehicle, setEditedVehicle] = useState(null);

  const [selectedUserId, setSelectedUserId] = useState(null); // State to store the selected user ID

  // Function to open the modal for editing a vehicle
  const openModal = (vehicle, userId) => {
    setEditedVehicle(vehicle);
    setVehicleModal(true);
    setSelectedUserId(userId);
  }; 
  const onClose = (event) => {
    setIsModalOpen(false);
  }; 

  // Function to close the modal
  const closeModal = () => {
    setVehicleModal(false);
    setEditedVehicle(null); // Reset editedVehicle
    setSelectedUserId(null); // Reset selected user ID
  };


    const handleDeleteUser = (userId) => {
        try {
          const database = getDatabase();
          const userref = ref(database, `/users/${userId}`);
    
          // Remove the vehicle entry from the database
          remove(userref)
            .then(() => {
              // Show a success message (you may implement this according to your UI)
              console.log("User deleted successfully!");
              toast.info(`User deleted successfully!`, {
                autoClose: 3000,
                position: toast.POSITION.TOP_CENTER,
              });
             
              // You might also want to refresh the data or update the UI accordingly
            })
            .catch((error) => {
              console.error("Error deleting user:", error); 
              // Handle the error (you may implement this according to your UI)
            });
        } catch (error) {
          console.error("Error deleting user:", error);
          // Handle any potential errors (you may implement this according to your UI)
        }
      };
   
  const createHandleMenuClick = (menuItem) => {
    console.log("this is user data receved.....",user.key);
    
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  return ( 
    <Dropdown>
      <MenuButton >Action</MenuButton>
      <Menu slots={{ listbox: AnimatedListbox }}>
        <MenuItem onClick={createHandleMenuClick('Language settings')} sx={{backgroundColor : green[500], margin : "2px"}}>
          Edit
        </MenuItem>
        <MenuItem onClick={createHandleMenuClick('Profile')} sx={{backgroundColor : 'black',margin : '2px'}}>Rewards</MenuItem>
        <MenuItem onClick={createHandleMenuClick('Log out')} sx={{backgroundColor : red[500]}}>Delete</MenuItem>
        <MenuItem onClick={createHandleMenuClick('Profile')} sx={{backgroundColor : 'black',margin : '2px'}}>Suspend</MenuItem>
      </Menu>
    </Dropdown>
  );
}

export  function MenuIntroduction1(data) {
  const userId = data.data
  
  // console.log("handledata......handledata......",handledata);
  
  console.log("key................key...",data);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userModal, setuserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [addUserModel, setAddUserModel] = useState(false)

  // const handleDeleteAccount = () => { 
  //   console.log("this is a user id for delete user....",userId);
    
  //   // You might want to show a confirmation dialog before deleting the user
  //   try {
  //     const database = getDatabase();
  //     const userref = ref(database, `/users/${userId.data}`);

  //     // Remove the vehicle entry from the database
  //     remove(userref)
  //       .then(() => {
  //         // Show a success message (you may implement this according to your UI)
  //         console.log("User deleted successfully!");
  //         toast.info(`User deleted successfully!`, {
  //           autoClose: 3000,
  //           position: toast.POSITION.TOP_CENTER,
  //         });
         
  //         // You might also want to refresh the data or update the UI accordingly
  //       })
  //       .catch((error) => {
  //         console.error("Error deleting user:", error);
  //         // Handle the error (you may implement this according to your UI)
  //       });
  //   } catch (error) {
  //     console.error("Error deleting user:", error);
  //     // Handle any potential errors (you may implement this according to your UI)
  //   }

  // } 

  const handleDeleteAccount = () => { 
    console.log("this is a user id for delete user....", userId);
  
    // Confirmation dialog
    // if (!window.confirm("Are you sure you want to delete this user?")) {
    //   return; // Exit if user cancels
    // }
  
    try {
      const database = getDatabase();
      const userRef = ref(database, `/users/${userId}`);
  
      // Remove the user entry from the database
      remove(userRef)
        .then(() => {
          console.log("User deleted successfully!");
          // handledata();
          toast.info("User deleted successfully!", {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
          // Refresh data or update UI
         // function to refresh user data
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
          toast.error("Failed to delete the user. Please try again.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("An unexpected error occurred. Please try again.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
 const handleAddNewUser = () => {
  setAddUserModel(true);
  // handledata();
 }

  const handleEditUser = () => {
    console.log("this is model");
    
    setuserModal(true);
    // handledata();
  }
   // Function to close the modal
   const closeModal = () => {
    setuserModal(false);
    setAddUserModel(false);
    // setEditedUser(null); // Reset editedVehicle
    setSelectedUserId(null); // Reset selected user ID
  };
    const createHandleMenuClick = (menuItem) => {
      // console.log("this is user data receved.....",user.key);
        return () => {
          console.log(`Clicked on ${menuItem}`);
        };
      };
    
      return (<>
      
        <Dropdown>
          <MenuButton >Action</MenuButton>
          <Menu slots={{ listbox: AnimatedListbox }}>
            <MenuItem onClick={createHandleMenuClick('Profile')} sx={{backgroundColor : 'black'}}>suspend</MenuItem>
            <MenuItem onClick={handleAddNewUser} sx={{backgroundColor : 'black'}}>add a User</MenuItem>
            <MenuItem onClick={handleDeleteAccount} sx={{backgroundColor : red[500]}}>Delete Account</MenuItem>
            <MenuItem onClick={handleEditUser} sx={{backgroundColor : green[500]}}>
              Edit
            </MenuItem>
          </Menu>
        </Dropdown>
          <EditUserModel
          isOpen={userModal}
          onClose={closeModal}
          // user={handleEditUser}
          userId={userId}
          // user={user}
        />
        <AddUserModel
        isOpen={addUserModel}
        onClose={closeModal}
        />
      </>
      );
    }
const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Listbox = styled('ul')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 50px;
  border-radius: 12px;
  overflow: auto;
  outline: 0;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `,
);

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      'The `AnimatedListbox` component cannot be rendered outside a `Popup` component',
    );
  }

  const verticalPlacement = popupContext.placement.split('-')[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }
  `,
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }
  `,
);
