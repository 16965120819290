import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { update, ref, get, getDatabase } from "firebase/database";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditUserModel = ({ isOpen, onClose, userId }) => {
  const [editedUser, setEditedUser] = useState({});

  // Fetch user data when modal opens
  useEffect(() => {
    if (userId) {
      const fetchUserData = async () => {
        try {
          const database = getDatabase();
          const userRef = ref(database, `/users/${userId}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setEditedUser(snapshot.val());
          } else {
            console.warn("User data not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast.error("Failed to load user data.");
        }
      };
      fetchUserData();
    }
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      if (
        !editedUser.userName ||
        !editedUser.userEmail ||
        !editedUser.userPhone ||
        !editedUser.userPassword
      ) {
        throw new Error("Please fill in all required fields.");
      }

      const database = getDatabase();
      const userRef = ref(database, `/users/${userId}`);

      const newData = {
        userName: editedUser.userName,
        userNumber: editedUser.userPhone,
        userEmail: editedUser.userEmail,
        Password: editedUser.userPassword,
      };

      await update(userRef, newData);

      toast.success("User updated successfully!", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      onClose(); // Close modal after saving
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error(error.message || "Failed to update user.");
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="edit-user-modal-title"
      aria-describedby="edit-user-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: 400,
          borderRadius: 5,
        }}
      >
        <h2>Edit User</h2>
        <form className="p-3">
          <div className="mb-3">
            <label htmlFor="userName" className="form-label">
              User Name:
            </label>
            <input
              type="text"
              id="userName"
              name="userName"
              className="form-control"
              value={editedUser.userName || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="userEmail" className="form-label">
              Email:
            </label>
            <input
              type="email"
              id="userEmail"
              name="userEmail"
              className="form-control"
              value={editedUser.userEmail || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="userPhone" className="form-label">
              Mobile Number:
            </label>
            <input
              type="text"
              id="userPhone"
              name="userPhone"
              className="form-control"
              value={editedUser.userPhone || ""}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="userPassword" className="form-label">
              Password:
            </label>
            <input
              type="text"
              id="userPassword"
              name="userPassword"
              className="form-control"
              value={editedUser.userPassword || ""}
              onChange={handleChange}
            />
          </div>
          <div className="d-grid gap-2">
            <button type="button" className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EditUserModel;
