import React from "react";
import { Box, TextField, Avatar, Dialog, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import BatteryStatusChip from "../layouts/Escort/BatteryStatusChip";
import soundFile from "../Raw/Siren.wav";
const DialogBox = ({ userInfo, open, onClose }) => {
  const navigate = useNavigate();

  const handelData = () => {
    // const audio = new Audio(soundFile);
    //   audio.loop = false; // Disable looping
    //   audio.pause(); // Pause the audio
    //   audio.currentTime = 0;
    onClose();
    navigate(`/ShortDistanceEscort/${userInfo?.userObjectKey}`);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          width: 250,
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          backgroundColor: "#ffffff",
        }}
      >
        <Avatar alt="User Photo" src={userInfo?.imgVehicle} sx={{ width: 80, height: 80 }} />
        <Link to={`/ShortDistanceEscort/${userInfo?.userObjectKey}`}>
          <Button
            variant="contained"
            fullWidth
            onClick={handelData}
            sx={{
              backgroundColor: "#d32f2f",
              color: "#ffffff",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#c62828" },
            }}
          >
            Short Distance Escort Request
          </Button>
        </Link>
        <Box
          sx={{
            width: "100%",
            padding: 1,
            backgroundColor: "#00bcd4",
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 1,
          }}
        >
          {userInfo?.userName}
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: 1,
            backgroundColor: "#d32f2f",
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 1,
          }}
        >
          {userInfo?.userPhoneP}
        </Box>
        <BatteryStatusChip status={(userInfo?.BatteryIndicator * 100).toFixed(2)} />
        {/* <Link to={`/ShortDistanceEscort/${userInfo?.userObjectKey}`}> */}
        <Button
          onClick={handelData}
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#000000",
            color: "#ffffff",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "#333333" },
          }}
        >
          Escort
        </Button>
        {/* </Link> */}
      </Box>
    </Dialog>
  );
};
export default React.memo(DialogBox);
