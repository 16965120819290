import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { push, ref, getDatabase } from "firebase/database";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUserModel = ({ isOpen, onClose }) => {
  const [newUser, setNewUser] = useState({
    userName: "",
    userEmail: "",
    userPhone: "",
    userPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      if (
        !newUser.userName ||
        !newUser.userEmail ||
        !newUser.userPhone ||
        !newUser.userPassword
      ) {
        throw new Error("Please fill in all required fields.");
      }

      const database = getDatabase();
      const usersRef = ref(database, `/users`);

      // Add new user
      await push(usersRef, newUser);

      toast.success("User added successfully!", {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });

      setNewUser({ userName: "", userEmail: "", userPhone: "", userPassword: "" }); // Reset form
      onClose(); // Close modal
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error(error.message || "Failed to add user.");
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="add-user-modal-title"
      aria-describedby="add-user-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: 400,
          borderRadius: 5,
        }}
      >
        <h2>Add New User</h2>
        <form className="p-3">
          <div className="mb-3">
            <label htmlFor="userName" className="form-label">
              User Name:
            </label>
            <input
              type="text"
              id="userName"
              name="userName"
              className="form-control"
              value={newUser.userName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="userEmail" className="form-label">
              Email:
            </label>
            <input
              type="email"
              id="userEmail"
              name="userEmail"
              className="form-control"
              value={newUser.userEmail}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="userPhone" className="form-label">
              Mobile Number:
            </label>
            <input
              type="text"
              id="userPhone"
              name="userPhone"
              className="form-control"
              value={newUser.userPhone}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="userPassword" className="form-label">
              Password:
            </label>
            <input
              type="text"
              id="userPassword"
              name="userPassword"
              className="form-control" 
              value={newUser.userPassword}
              onChange={handleChange}
            />
          </div>
          <div className="d-grid gap-2">
            <button type="button" className="btn btn-primary" onClick={handleSave}>
              Add User
            </button>
            <button type="button" className="btn btn-secondary" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default AddUserModel;
