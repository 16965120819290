import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Paper,
  Chip,
  Grid,
  Card,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { getDatabase, ref, onValue } from "firebase/database";
import { GoogleMap, useJsApiLoader, Marker, Polyline } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import BatteryStatusChip from "../../layouts/Escort/BatteryStatusChip";
import MDBox from "../../components/MDBox";
import UserConnected from "../../layouts/Escort/userConnected";
import Zone from "../../layouts/Escort/zone";
import Movement from "../../layouts/Escort/movement";
import UserFBK from "../../layouts/Escort/userFBK";
import LongMenu from "Utils/action";
import Timer from "Utils/Timer";

const ShortDistanceEscort = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg", // Replace with the new API key
  });
  const { id } = useParams();

  const [mapCenter, setMapCenter] = useState({ lat: 20.76, lng: 72.96 });
  const [initialMapCenter, setInitialMapCenter] = useState(null);
  const [userLocation, setUserLocation] = useState([]);
  const [allData, setAllData] = useState(null);
  const [escortData, setEscortData] = useState({});
  const isLargeScreen = useMediaQuery("(min-width:1200px)");

  useEffect(() => {
    const db = getDatabase();
    const docRef = ref(db, `users/${id}`);
    // console.warn("docRef", docRef);

    const unsubscribe = onValue(docRef, (snapshot) => {
      const data = snapshot.val();
      console.log("data", data);
      setAllData(data);
      if (data) {
        const { currentLat, currentLong } = data;

        if (!initialMapCenter) {
          setInitialMapCenter({ lat: currentLat, lng: currentLong });
        }

        setMapCenter({ lat: currentLat, lng: currentLong });
        setUserLocation((prevUserLocation) => [
          ...prevUserLocation,
          { lat: currentLat, lng: currentLong },
        ]);
        console.log(allData?.ShortDistance_Escort);
        // const vehicleReg = allData?.ShortDistance_Escort[allData?.ShortDistance_Escort.length - 1];
        if (allData?.ShortDistance_Escort && Object.values(allData?.ShortDistance_Escort)){
          setEscortData(
            Object.values(allData?.ShortDistance_Escort)[
              Object.values(allData?.ShortDistance_Escort).length - 1
            ]
          );
          console.warn(escortData);
        }
          
         
      }
    });

    return () => {
      unsubscribe();
    };
  }, [id, initialMapCenter]);

  // const handleToggle = async (user, index) => {
  //   console.log(user, index);
  // };
  // if (!window.google || !window.google.maps) {
  //   const { isLoaded } = useJsApiLoader({
  //     googleMapsApiKey: "AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg",
  //   });

  //   if (!isLoaded) {
  //     return null;
  //   }
  // }

  const mapStyles = { height: isLargeScreen ? "550px" : "400px", width: "100%" };
  if (!isLoaded) {
    return (
      <div
        style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}
      >
        <h1>Loading the map...</h1>
      </div>
    );
  }
  return (
    <>
      <DashboardLayout>
        <MDBox>
          <TableContainer component={Paper}>
            <Table aria-label="user table">
              <TableHead>
                <TableBody>
                  <TableCell>User Name</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell align="center">Battery Indicator</TableCell>
                  <TableCell align="center">User Connected</TableCell>
                  <TableCell align="center">Zone Fencing</TableCell>
                  <TableCell align="center">Movement Indicator</TableCell>
                  {/* <TableCell align="center">Tracking</TableCell> */}
                  <TableCell align="center">UserFBK</TableCell>
                  <TableCell align="center">vehicle Image</TableCell>
                  <TableCell align="center">vehicle Registration No</TableCell>
                  <TableCell align="center">User not responded since</TableCell>
                  <TableCell align="center">Action</TableCell>
                  <TableRow sx={{ height: "auto" }}>
                    <TableCell sx={{ padding: "6px" }} component="th" scope="row">
                      <Avatar
                        src={allData?.userName}
                        alt={allData?.userName}
                        style={{ marginRight: "10px" }}
                      />
                      {allData?.userName}
                    </TableCell>
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                      {allData?.userPhone}
                    </TableCell>
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                      <BatteryStatusChip status={(allData?.BatteryIndicator * 100).toFixed(2)} />
                    </TableCell>
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                      <UserConnected status={allData?.socketId} />
                    </TableCell>
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                      <Zone status={escortData?.zone_fenceing} />
                    </TableCell>
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                      <Movement status={allData?.is_moving} />
                    </TableCell>
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                      <UserFBK status={allData?.userFBK} />
                    </TableCell>
                    <TableCell
                      // align="center"
                      className="rounded"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        src={escortData?.imgVehicle}
                        alt={escortData?.imgVehicle}
                        // align="center"
                        // style={{ marginRight: "10px" }}
                      />
                    </TableCell>
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                      {escortData?.vehicleReg}
                    </TableCell>
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                    {allData?.arrivalpopup && allData?.arrivalpopuptimestamp && <Timer firebaseTime={allData?.arrivalpopuptimestamp}/>}
                    </TableCell>
                    
                    <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                    <LongMenu props={allData}/>
                    </TableCell>
                    
                  </TableRow>
                </TableBody>
              </TableHead>
            </Table>
          </TableContainer>
        </MDBox>
        <GoogleMap mapContainerStyle={mapStyles} center={initialMapCenter || mapCenter} zoom={15}>
          <Marker position={mapCenter} visible={true} />
          <Polyline
            path={userLocation}
            options={{
              strokeColor: "blue",
              strokeOpacity: 0.8,
              strokeWeight: 15,
            }}
          />
        </GoogleMap>
      </DashboardLayout>
    </>
  );
};

export default React.memo(ShortDistanceEscort);
