import React from "react";
import { Chip } from "@mui/material";

const Zone = ({ status }) => {
  // Determine the color based on the status value
  const getColor = () => {
    if (status == "No Fence") {
      return { color: "#212121" };
    } else if (status == "Outside Zone") {
      return { color: "#d50000" };
    } else if (status == "Insde Zone") {
      return { color: "#1b5e20" };
    }
    return "grey"; // Default color for invalid statuses
  };

  const color = getColor();

  return (
    <Chip
      label={
        <div style={{ textAlign: "center" }}>
          <span>{status}</span>
        </div>
      }
      style={{
        backgroundColor: color.color,
        color: "white",
        borderRadius: "5px",
        fontWeight: "bold",
        height: "auto",
        padding: "5px",
      }}
    />
  );
};

export default React.memo(Zone);
