import React from "react";
import { Chip } from "@mui/material";

const Movement = ({ status }) => {
  // Determine the color based on the status value
  const getColor = () => {
    if (status) {
      return { color: "#0d47a1" ,text:"In Motion"};
    } else
      return { color: "#d50000",text: "Stopped"};
    
  };

  const color = getColor();

  return (
    <Chip
      label={
        <div style={{ textAlign: "center" }}>
          <span>{color.text}</span>
        </div>
      }
      style={{
        backgroundColor: color.color,
        color: "white",
        borderRadius: "5px",
        fontWeight: "bold",
        height: "auto",
        padding: "5px",
      }}
    />
  );
};

export default React.memo(Movement);
