import React from "react";
import { Box, Avatar, Dialog, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import socket from "../socket/SocketService";
import { toast } from "react-toastify";

const ArrivalDialogBox = ({ userInfo, open, onClose }) => {
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    body: Yup.string().required("Body is required"),
  });
  console.log("ArrivalDialogBox")
console.log(userInfo)
  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
    },
    validationSchema,
    onSubmit: (values) => {
      socket.emit("sendpushnotification", {
        title: values.title,
        body: values.body,
        token: userInfo?.deviceToken || userInfo?.userData?.deviceToken,
      });
      toast.info(`Push notification is sent to the user`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
      onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          width: "100%",
          padding: 4,
          borderRadius: 3,
          boxShadow: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          backgroundColor: "#f7f9fc",
          border: "1px solid #e0e0e0",
        }}
      >
        <Avatar
          alt="User Photo"
          src={userInfo?.imgVehicle}
          sx={{
            width: 100,
            height: 100,
            marginBottom: 2,
            border: "2px solid #1976d2",
          }}
        />

        <TextField
          id="title"
          name="title"
          label="Title For Push Notification"
          fullWidth
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <TextField
          id="body"
          name="body"
          label="Body For Push Notification"
          fullWidth
          multiline
          rows={4}
          value={formik.values.body}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.body && Boolean(formik.errors.body)}
          helperText={formik.touched.body && formik.errors.body}
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#1976d2",
            color: "#ffffff",
            fontWeight: "bold",
            padding: "12px 0",
            fontSize: "16px",
            textTransform: "none",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
        >
          Send Notification
        </Button>
      </Box>
    </Dialog>
  );
};

export default React.memo(ArrivalDialogBox);
