import React from "react";
import { Chip } from "@mui/material";
import { red, orange, green } from "@mui/material/colors";

const BatteryStatusChip = ({ status }) => {
  // Determine the color based on the status value
  const getColor = () => {
    if (status >= 0 && status <= 20) {
      return { color: "#d50000", text: "Low Battery" };
    } else if (status >= 21 && status <= 50) {
      return { color: "#ff6d00", text: "Medium Battery" };
    } else if (status >= 51) {
      return { color: "#1b5e20", text: "High Battery" };
    }
    return "grey"; // Default color for invalid statuses
  };

  const color = getColor();

  return (
    <Chip
      label={
        <div style={{ textAlign: "center" }}>
          <span>{color.text}</span>
          <br />
          <span>{status}%</span>
        </div>
      }
      style={{
        backgroundColor: color.color,
        color: "white",
        borderRadius: "5px",
        fontWeight: "bold",
        height: "auto",
        padding: "5px",
      }}
      icon={<span style={{ color: color.color, fontSize: "12px" }}>●</span>}
    />
  );
};

export default React.memo(BatteryStatusChip);
