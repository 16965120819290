import React, { useCallback, useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import { Box, Button, Paper, Typography, Grid } from "@mui/material";
import { toast } from "react-toastify";
import mapicon from "../../assets/images/mapIcon.jpg";
import stopicon from "../../assets/images/stop.png";
import "react-toastify/dist/ReactToastify.css";
import "./map.css";

const libraries = ["places"]; // Move outside to prevent unnecessary reloading

const App1 = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg",
  });

  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [center, setCenter] = useState({ lat: 22.7196, lng: 75.8577 });
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [tripStop, setTripStop] = useState([]);
  // const userId = "-NwrMtajniR3U1M0zYqk";
  const { userId } = useParams();
  const params = useParams();


  const fetchUserData = useCallback(() => {
    const db = getDatabase();
    const userRef = ref(db, `users/${params.id}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      console.log("userData....",userData);
      
      if (userData) {
        const liveLocation = {
          lat: userData.LiveLatitude,
          lng: userData.LiveLongitude,
        };
        setCenter(liveLocation);

        const escortData = userData.ShortDistance_Escort;
        console.log("escortData.....",escortData);
        

if (escortData) {
  const keys = Object.keys(escortData); // Get all keys
  const lastKey = keys[keys.length - 1]; // Find the last key

  if (lastKey) {
    const lastValue = escortData[lastKey]; // Access the last value
    console.log("lastvalues......",lastValue);
    
    // Set origin and destination based on the last value
    setOrigin({ lat: lastValue.Latitude, lng: lastValue.Longitude });
    setDestination(center); // Assuming 'center' is predefined

    // If there are stops, process the stops
    const stopInfo = lastValue.stops;
    console.log("stopInfo.....",stopInfo);

    if (stopInfo) {
      const stops = [];
      for (const stopdata in stopInfo) {
        const stop = stopInfo[stopdata];
        console.log("Stop data:", stop);
        stops.push(stop); // Add stops to the array
      }
      setTripStop(stops); // Update state with the array
    }

    console.log('Last Escort Data:', lastValue);
  }
}

      }
    });
  }, []);

  const calculateRoute = async () => {
    if (!origin || !destination) {
      toast.error("Please provide both origin and destination.");
      return;
    }

    try {
      const directionsService = new window.google.maps.DirectionsService();
      // const results = await directionsService.route({
      //   origin,
      //   destination,
      //   travelMode: window.google.maps.TravelMode.DRIVING,
      // });
      const results = await directionsService.route({
        origin: new window.google.maps.LatLng(origin.lat, origin.lng), // Convert to LatLng
        destination: new window.google.maps.LatLng(destination.lat, destination.lng), // Convert to LatLng
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      console.log("result..",results);
      
      setDirectionsResponse(results);
      setDistance(results.routes[0].legs[0].distance.text);
      setDuration(results.routes[0].legs[0].duration.text);
    } catch (error) {
      toast.error("Failed to calculate route.");
      console.error("Directions API Error:", error);
    }
  };

  const clearRoute = () => {
    setDirectionsResponse(null);
    setDistance("");
    setDuration("");
  };

  useEffect(() => {
    fetchUserData();
    calculateRoute();
  }, [fetchUserData]);

  useEffect(() => {
    if (origin && destination) {
      calculateRoute();
    }
  }, [origin, destination]); // Trigger calculateRoute when origin or destination changes

  
  console.log("Origin:", origin);
console.log("Destination:", destination);


  // if (loadError) return <div>Error loading Google Maps API</div>;
  if (!isLoaded)
    return (
      <div style={{ display: "flex", justifyContent: "center", height: "100vh" }}>
        <h1>Loading the map...</h1>
      </div>
    );

  return (
    <Box position="relative" height="100vh" width="100vw">
      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onLoad={(map) => setMap(map)}
      >
        <Marker
          position={center}
          icon={{
            url: mapicon,
            scaledSize: new window.google.maps.Size(50, 50), // Set desired width and height
            origin: new window.google.maps.Point(0, 0), // Specify the origin point
            anchor: new window.google.maps.Point(25, 25), // Set anchor point at the center
          }}
        />
        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}

        {tripStop.map((stop, index) => {
  const user = { lat: stop.stopLatitude, lng: stop.stopLongitude };
  return (
    <Marker
      key={index}
      position={user}
      icon={{
        url: stopicon,
        scaledSize: new window.google.maps.Size(50, 50), // Set desired width and height
        anchor: new window.google.maps.Point(25, 25), // Set anchor point at the center
      }}
    />
  );
})}

        
      </GoogleMap>

    </Box>
  );
};

export default App1;